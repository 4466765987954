import React from "react"
import { graphql } from "gatsby"
import BookLink from "../components/bookLink"
import Layout from "../components/layout"
import SEO from "../components/seo"
import config from "../../config.json"

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => (
      <li
        style={{
          padding: "0",
          display: "flex",
          margin: "0",
          textDecoration: "none",
          listStyle: "none",
          width: "300px",
          marginBottom: "40px",
          flexWrap: "wrap",
        }}
      >
        <BookLink key={edge.node.id} post={edge.node} />
      </li>
    ))

  return (
    <Layout>
      <SEO
        title={`${config.author}'s free work`}
        description={`Read ${config.author}'s books and short stories for free, online, ebook!`}
      />
      <h1>{config.author}'s Public Domain Work</h1>
      <p>
        H.P. Lovecraft, in full Howard Phillips Lovecraft, (born August 20,
        1890, Providence, Rhode Island, U.S.—died March 15, 1937, Providence),
        American author of fantastic and macabre short novels and stories, one
        of the 20th-century masters of the Gothic tale of terror.
      </p>
      <ul
        style={{
          display: "flex",
          margin: "0",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        {Posts}
      </ul>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
          }
        }
      }
    }
  }
`
